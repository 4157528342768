<template>
  <div class="container instructions">
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="9">
        <BannerVsa />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-tabs pills vertical nav-wrapper-class="col-12 col-md-3"
                active-nav-item-class="btn-success"
                active-tab-class="font-weight-bold text-success"
        >
          <b-tab title="O akciji" active>
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">ZAPIŠIMO SPOMINE, ČETRTIČ!</h2>
                  <p>
                    <b>V letošnjem marcu bo potekala že četrta vseslovenska akcija beleženja spominov starejših.</b>
                  </p>
                  <p>
                    V Zavodu Dobra pot jo prirejamo z željo opozoriti na družbeno vlogo starejših kot pomembnih nosilcev spomina oziroma ustnega izročila, hkrati pa z njo izpostavljamo pomen ohranjanja dediščine.</p>

                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/QsLA9S6nEy8?ab_channel=ZavodDobrapot"
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen style="width: 100%; margin-bottom: 10px;"></iframe>

                  <p>Naj spomnimo, da je naša aplikacija za beleženje pričevanj starejših leta 2022 prejela nagrado Sveta Evrope in Evropske komisije, do danes pa je v njej zbranih že skoraj 350 zgodb starejših!</p>

                  <p>Med 17. in 30. marcem 2025 se bo tako kot prejšnja leta po vsej Sloveniji odvil niz beleženj pričevanj, s katerimi bomo krepili vezi med generacijami in hkrati ohranjali dediščino. Prijave so že odprte!</p>

                  <p>Sodeluje lahko vsak, in sicer kot pričevalec (starejši, ki bi podelil svoje spomine) ali zapisovalec (tisti, ki bi spomine starejših zabeležil oz. vnesel v aplikacijo). K sodelovanju vabimo tudi šole, društva, druge ustanove in organizacije ... skratka vse, ki imate radi dediščino ali radi pripovedujete zgodbe.</p>

                  <p>Za vse, ki vas sodelovanje zanima, je na levi strani v meniju poleg prijavnice na voljo že precej gradiva, napotkov in nasvetov. Vabljeni k sodelovanju in ohranjanju dediščine!</p>
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/cuktfS4VcwY?t=3s&ab_channel=ZavodDobrapot"
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen style="width: 100%;"></iframe>


                  <!--                  <p>-->
                  <!--                    Prijavite se lahko kot <b>zapisovalci spomina</b> (tisti, ki bi zgodbe starejših zabeležili oz.-->
                  <!--                    posneli in-->
                  <!--                    vnesli v aplikacijo) ali kot <b>pričevalci</b> (starejši, ki bi želeli podeliti svoje spomine).</p>-->
                  <!--                  <p>Spomini se bodo zapisovali v namensko aplikacijo Zapisi spomina, ki omogoča, da vsakdo objavi-->
                  <!--                    pričevanja svojih dedkov, babic ali drugih starejših, in tako digitalizira dragoceno ustno-->
                  <!--                    izročilo.</p>-->
                  <!--                  <p>-->
                  <!--                    Za vse prijavljene bo v začetku marca organizirana spletna delavnica, na kateri bomo podrobneje-->
                  <!--                    predstavili potek dvotedenske akcije in odgovorili na vsa vaša vprašanja.</p>-->
                  <!--                  <p>-->
                  <!--                    Delavnica bo <b>7. marca ob 18. uri</b> prek platforme Google Meets.<br/>-->

                  <!--                    Povezava za delavnico: <a href="https://meet.google.com/uzv-fbqy-hsv">https://meet.google.com/uzv-fbqy-hsv</a><br/>-->
                  <!--                    Vljudno vabljeni-->
                  <!--                  </p>-->
                  <!--                  <p>-->
                  <!--                    Če še ne poznate aplikacije Zapisi spomina, si oglejte spodnja video-navodila za njeno uporabo.</p>-->
                  <!--                  <p> Ne odlašajte s prijavo in se vidimo! 😉-->
                  <!--                  </p>-->

                  <!--                  &lt;!&ndash;                  <iframe width="560" height="315" src="https://www.youtube.com/embed/cuktfS4VcwY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%;"></iframe>&ndash;&gt;-->

                  <!--                  <p>-->
                  <!--                    <br/><br/>-->
                  <!--                    <small>-->
                  <!--                      <em>-->
                  <!--                        Aktivnosti se financirajo prek platforme Huda pobuda, ki jo upravlja Inštitut Danes je nov dan,-->
                  <!--                        in projekta Digitalni dediščinski inkubator - DDI, ki se sofinancira iz sklada za NVO-->
                  <!--                        Ministrstva za javno upravo v okviru programa za digitalno preobrazbo nevladnih in-->
                  <!--                        prostovoljskih organizacij.-->
                  <!--                      </em>-->
                  <!--                    </small>-->
                  <!--                  </p>-->
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Prijavnica">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSelWJTsxJzexm4OT1spSA02GqMaUKbvpI92z3yPQJOWym9KWw/viewform?embedded=true"
                    width="640" height="2311" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%;">
                    Loading…
                  </iframe>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Navodila za šole">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Navodila za šole</h2>
                  <p>
                    <a class="btn btn-link pl-0"
                       href="https://www.dobra-pot.si/assets/dobra-pot/documents/Navodila_za_sole_VSA_2023.pdf"
                       download><i class="fa fa-download"></i> Prenos datoteke</a>
                    <iframe
                      src="https://www.dobra-pot.si/assets/dobra-pot/documents/Navodila_za_sole_VSA_2023.pdf#toolbar=0"
                      width="640"
                      height="1200" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%;">Loading…
                    </iframe>
                  </p>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Učni listi">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Učni listi</h2>
                  <a class="btn btn-link pl-0"
                     href="https://www.dobra-pot.si/assets/dobra-pot/documents/Ucni_listi_ZS_1.pdf" download><i
                    class="fa fa-download"></i> Prenos datoteke</a>
                  <iframe src="https://www.dobra-pot.si/assets/dobra-pot/documents/Ucni_listi_ZS_1.pdf#toolbar=0"
                          width="640"
                          height="1200" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%;">Loading…
                  </iframe>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Primer delavnice">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Primer delavnice</h2>
                  <p>
                    <a class="btn btn-link pl-0"
                       href="https://www.dobra-pot.si/assets/dobra-pot/documents/Primer%20delavnice.pdf" download><i
                      class="fa fa-download"></i> Prenos datoteke</a>
                    <iframe src="https://www.dobra-pot.si/assets/dobra-pot/documents/Primer%20delavnice.pdf#toolbar=0"
                            width="640"
                            height="1200" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%;">Loading…
                    </iframe>
                  </p>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Smernice za intervjuje">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Primer delavnice</h2>
                  <p>
                    <a class="btn btn-link pl-0"
                       href="https://www.dobra-pot.si/assets/dobra-pot/documents/Smernice%20za%20intervju%20ZS.pdf"
                       download><i class="fa fa-download"></i> Prenos datoteke</a>
                    <iframe
                      src="https://www.dobra-pot.si/assets/dobra-pot/documents/Smernice%20za%20intervju%20ZS.pdf#toolbar=0"
                      width="640" height="1200" frameborder="0" marginheight="0" marginwidth="0"
                      style="width: 100%;">Loading…
                    </iframe>
                  </p>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Navodila za organizacije">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Navodila za organizacije</h2>
                  <p>
                    <a class="btn btn-link pl-0"
                       href="https://www.dobra-pot.si/assets/dobra-pot/documents/Navodila_za_organizacije_VSA_2023.pdf"
                       download><i class="fa fa-download"></i> Prenos datoteke</a>
                    <iframe
                      src="https://www.dobra-pot.si/assets/dobra-pot/documents/Navodila_za_organizacije_VSA_2023.pdf#toolbar=0"
                      width="640" height="1200" frameborder="0" marginheight="0" marginwidth="0"
                      style="width: 100%;">Loading…
                    </iframe>
                  </p>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Izjava o soglasju">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Izjava o soglasju</h2>
                  <p>
                    <a class="btn btn-link pl-0"
                       href="https://www.dobra-pot.si/assets/dobra-pot/documents/Izjava%20o%20soglasju%20-%20posamezniki.pdf"
                       download><i class="fa fa-download"></i> Prenos datoteke</a>
                    <iframe
                      src="https://www.dobra-pot.si/assets/dobra-pot/documents/Izjava%20o%20soglasju%20-%20posamezniki.pdf#toolbar=0"
                      width="640" height="1200" frameborder="0" marginheight="0" marginwidth="0"
                      style="width: 100%;">Loading…
                    </iframe>
                  </p>
                </div>
              </div>
            </article>
          </b-tab>
          <b-tab title="Vprašanja in odgovori">
            <article class="post-area single-blog">
              <div class="about-me">
                <div class="about-me-text my-0 mx-0 px-0 py-0">
                  <h2 class="pt-0 mt-0">Vprašanja in odgovori</h2>
                  <p>
                    <a class="btn btn-link pl-0"
                       href="https://www.dobra-pot.si/assets/dobra-pot/documents/POGOSTA_VPRASANJA_IN_ODGOVORI_VSA_2023.pdf"
                       download><i class="fa fa-download"></i> Prenos datoteke</a>
                    <iframe
                      src="https://www.dobra-pot.si/assets/dobra-pot/documents/POGOSTA_VPRASANJA_IN_ODGOVORI_VSA_2023.pdf#toolbar=0"
                      width="640" height="1200" frameborder="0" marginheight="0" marginwidth="0"
                      style="width: 100%;">Loading…
                    </iframe>
                  </p>
                </div>
              </div>
            </article>
          </b-tab>
        </b-tabs>
      </b-col>

    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";
import BannerVsa from "@/components/BannerVsa";

export default {
  name: "Instructions",
  components: {
    BannerVsa
  },
  data() {
    return {
      users: []
    };
  },
  metaInfo() {
    return {
      title: "Navodila"
    };
  },
  mounted() {
  },
  methods: {}
};
</script>
<style lang="scss">
.instructions {
  text-align: justify;
  color: #6d5a3f;

  a,
  .nav-item a {
    color: #59971f !important;
    font-weight: bold;
  }

  .nav-item a.btn-success {
    background-color: #59971f !important;
    color: white !important;
    font-weight: 600;
    text-transform: uppercase;
  }

  h2 {
    font-size: 18px;
    margin: 40px 0px 20px 0px;
  }

  h3 {
    font-size: 15px;
    margin: 20px 0px;
  }

  p {
    margin-bottom: 10px !important;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
  }

}
</style>
